* {
  box-sizing: border-box;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


span.drag {
  display: inline-block;
  width: 16px;
  height: 8px;
  position: relative;
  top: -3px;
}

span.drag,
span.drag::before, span.drag::after {
  background-image: radial-gradient(#747474 40%, transparent 40%);
  background-size: 4px 4px;
  background-position: 0 100%;
  background-repeat: repeat-x;
}

span.drag::before, span.drag::after {
  content: '';
  display: block;
  width: 100%;
  height: 33%;
}

 span.drag::after {
   margin-top: 5px;
   height: 66%;
 }
 
.play {
    display: inline-block;
    width: 12px;
    height: 16px;
    border-top: 8px solid transparent;
    border-left: 12px solid #000;
    border-bottom: 8px solid transparent;
    position: relative;
    top: 3px;
}
.App {
  max-width: 960px;
  padding: 20px;
  margin: 0 auto;
}
.page-items {
  margin-top: 20px;
}
.page-item {
  border: 1px dashed black;
  padding: 10px;
  width: 100%;
  position: relative;
  margin-bottom: 20px;
  background: #ffffff;
}

.page-item img {
  height: 200px;
}
.page-item-spread {
  display: flex;
  justify-content: space-between;
}

.page-item-spread .handle, .page-item-spread .title {
  height: 100%;
  min-height: 0;
  width: 180px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: left;
  font-size: 12px;
}

.page-item-spread .input-item {
  flex-grow: 1;
  margin: 0 100px;
}

.page-item-left {
  text-align: center;
}
.page-item textarea {
  display: block;
  width: 100%;
  margin-top: 20px;
  height: 40px;
  font-size: 18px;
}

.page-item .main-timing {
  margin: 10px 10px 20px 10px;
}
.page-item .time {
  font-size: 13px;
  width: 100px;
  margin-right: 10px;
  margin-left: 5px;
}

.page-item-right .time2 {
  margin-left: 10px;
  width: 90px;
  font-size: 16px;
}

.page-item-right .word-item {
  display: inline-block;
  margin: 5px 20px 5px 5px;
}

.page-item .handle {
  /*position: absolute;*/
  /*right: 10px;*/
  /*top: 10px;*/
  color: #747474;
  cursor: move;
}

.page-item .title {
  /*position: absolute;*/
  /*top: 10px;*/
  /*left: 10px;*/
  cursor: pointer;
}

.page-item .title input {
  font-size: 10px;
}

.chooser {
  border: 1px dotted #9a9a9a;
  padding: 10px 20px;
  margin-bottom: 10px;
  text-align: left;
}

.audio-holder {
  background: #ffffff;
  position: fixed;
  top: 0;
  width: 920px;
  z-index: 1;
  padding: 20px 20px;
  box-shadow: 0px 3px 10px 3px #d3d3d3;
  box-sizing: border-box;
}

.audio-preview:not(:first-child){
  margin-top: 20px;
}

.audio-preview .toggle {
  margin-left: 20px;
  font-size: 12px;
  padding: 4px 8px;
  border-radius: 5px;
}

.audio-preview .options {
  float: right;
  display: inline-block;
  font-size: 12px;
}

.content {

}

.main-help {
  height: 200px;
}

.chooser-holder {
  width: 100%;
  display: flex;
}

.chooser {
  flex-basis: 0;
  flex-grow: 1;
}

.chooser input {
  font-size: 12px;
}
.chooser div {
  font-weight: bold;
  margin-bottom: 5px;
  font-size: 14px;
}

.help {
  text-align: center;
  font-style: italic;
  font-size: 12px;
}

.audio-details{
  margin-top: 5px;
}


.options .zoom {
  width: 300px;
  margin-right: 10px;
  position: relative;
  top: 5px;
}

.main-chooser {
  margin-top: 100px;
}

.main-chooser a {
  text-decoration: none;
  color: black;
}

.audio-book-images {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.audio-book-image-item {
  border: 1px dotted #9a9a9a;
  padding: 10px;
  margin-top: 10px;
  position: relative;
}

.image-add-button input {
  opacity: 0;
  width: 1px;
  height: 1px;
}

.image-add-button {
  padding: 0;
  font-size: 12px;
  background: #e6e6e6;
  width: 180px;
  border: none;
  margin-right: auto;
}

.image-add-button label {
  display: block;
  padding: 5px;
  margin: 0 !important;
  cursor: pointer;
}

.audio-book-image-item .time {
  margin-top: 5px;
  display: block !important;
  box-shadow: none !important;
  text-align: center;
  padding: 0.2em !important;
}

.audio-book-image-item img {
  width: 50px;
  height: 80px;
}

.book-name {
  width: 100%;
}

.download-button {
  margin-top: 30px;
  margin-right: 20px;
}

.delete-button {
  background: transparent;
  border: none;
  opacity: 0;
  cursor: pointer;
  transition: all 200ms linear;
  position: relative;
  top: 5px;
  right: -10px;
}

.preview-check {
  font-size: 12px;
  opacity: 0;
}

.preview-check input {
  position: relative;
  top: 2px;
}

.page-item:hover .preview-check {
  opacity: 0.5;
}

.preview-check:hover {
  opacity: 1;
}

.delete-button img {
  width: 16px;
  height: 16px;
}

.page-item:hover .delete-button.dlt-page, .audio-book-image-item:hover .delete-button.dlt-img {
  opacity: 0.5;
}

.page-item:hover .delete-button.dlt-page:hover, .audio-book-image-item:hover .delete-button.dlt-img:hover {
  opacity: 1;
}

.chapter-extras {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-top: 10px;
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
